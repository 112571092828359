<template>
  <div>
    <div class="title flexB" style="display: block;">
      <h1>{{ $t("top-select-device-manager-details") }}</h1>
<!--      <div>
        &lt;!&ndash;        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >&ndash;&gt;
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
      </div>-->
    </div>
    <div class="contents liveList">
      <div class="box one filter" style="display: flex;flex-direction: row;flex-wrap: wrap;">
        <div class="flex">
          <p class="bold">{{ $t("search-keyword-title") }}</p>
          <select v-model="keywordType">
            <option value="all">{{ $t("search-keyword-all") }}</option>
            <option value="deviceId">{{ $t("device-data-id") }}</option>
            <option value="deviceName">{{ $t("device-data-device-name") }}</option>
            <option value="channelId">{{ $t("live-data-channel-id") }}</option>
            <option value="eventViewId">{{ $t("event-data-site-num") }}</option>
            <option value="eventName">{{ $t("event-data-site-name") }}</option>
          </select>
          <input
              type="text"
              v-model="keyword"
              @keydown.enter="search"
          />
        </div>
        <div class="flexC">
          <p class="bold">{{ $t("search-flight-start-date") }}</p>
          <Datepicker
              v-model="startYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
          <span>~</span>
          <Datepicker
              v-model="endYmd"
              :language="ko"
              :format="customFormatter"
              placeholder="YYYY-MM-DD"
          ></Datepicker>
        </div>
        <div class="buttonWrap" style="display: flex; margin-top: 0;">
          <button class="point medium" @click="search" style="width: 120px; margin-left: 20px;">
            {{ $t("btn-searching") }}
          </button>
          <button class="medium margin6" @click="reset" style="width: 120px;">
            {{ $t("btn-reset") }}
          </button>
        </div>
      </div>
      <div class="box one">
        <h2 class="bold">
          {{ $t("data-total") }} <span class="blue">{{ total }}</span
        >{{ $t("data-case-ea") }}
        </h2>
        <!-- <div class="none">
            <p>{{ $t("search-not-found") }}</p>
          </div> -->
          <table>
            <tr>
              <th>No</th>
              <th>{{ $t("device-data-flight-start") }}</th>
              <th>{{ $t("device-data-flight-end") }}</th>
              <th>{{ $t("device-data-id") }}</th>
              <th>{{ $t("device-data-device-name") }}</th>
              <th>{{ $t("device-data-manager-dept-name") }}</th>
              <th>{{ $t("live-data-ch-allocation-serial-num") }}</th>
              <th>{{ $t("live-data-channel-id") }}</th>
              <th>{{ $t("event-data-site-num") }}</th>
              <th>{{ $t("event-data-site-name") }}</th>
              <th>{{ $t("device-data-log-create-date") }}</th>
            </tr>
            <tr v-if="total == 0">
              <td colspan="11" style="text-align: center;">
                {{ $t("search-not-found") }}
              </td>
            </tr>
            <tr v-for="(data, i) in deviceLogList" :key="i">
              <td>{{ total - (size * currentPage + i) }}</td>
              <td>{{ data.startTime != null ? dateFormat(data.startTime) : "-" }}</td>
              <td>{{ data.endTime != null ? dateFormat(data.endTime) : "-"}}</td>
              <td>{{ data.deviceId }}</td>
              <td>{{ data.deviceName }}</td>
              <td>{{ data.workerDepartmentName }}</td>
              <td>{{ data.recordViewId }}</td>
              <td>{{ data.recordChannelId }}</td>
              <td>{{ data.eventViewId != null ? data.eventViewId : data.eventId }}</td>
              <td>{{ data.eventTitle }}</td>
              <td>{{ dateFormat(data.registerDate) }}</td>
            </tr>
          </table>
          <div class="pagination">
            <el-pagination
                small
                layout="prev, pager, next"
                :total="total"
                :page-size="size"
                @current-change="handleCurrentChange"
                :current-page="currentPage + 1"
            >
            </el-pagination>
          </div>
        </div>

      </div>
    </div>
</template>
<style>
.title{margin-top: 0;}
</style>
<script>
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import { fetchDeviceLogList } from "@/api/device";

export default {
  components: { Datepicker },
  name: "DroneFlight",
  data() {
    return {
      ko: ko,
      moment: moment,
      startYmd: null,
      endYmd: null,
      deviceLogList: [],
      total: 0,
      size: 10,
      currentPage: 0,
      keywordType: "all",
      keyword: null,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 5, sub: 1 });
  },
  mounted() {
    this.callFunctionsWhenMounted();
  },
  methods: {
    dateFormat(date) {
      return moment(date).format("YYYY.MM.DD HH:mm:ss");
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.getDeviceLog();
    },
    search(){
      if(this.startYmd != null || this.endYmd != null) {
        if (this.startYmd == null || this.endYmd == null || this.startYmd > this.endYmd) {
          return alert(this.$t("alert-message-check-regdate"));
        }
      }
      this.currentPage = 0;
      this.getDeviceLog();
    },
    getDeviceLog() {
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        keywordType: this.keywordType,
        keyword: this.keyword,
        isChannelData : true,
      };
      params["startDateRange.from"] = null;
      params["startDateRange.to"] = null;
      if(this.startYmd != null && this.endYmd != null) {
        params["startDateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["startDateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }
      fetchDeviceLogList(params).then((res) => {
        this.total = res.data.data.total;
        this.deviceLogList = res.data.data.content;
      });
    },
    handleRefresh() {
      this.reset();
      this.callFunctionsWhenMounted();
    },
    reset() {
      this.currentPage = 0;
      this.keywordType = "all";
      this.keyword = null;
      this.startYmd = null;
      this.endYmd = null;
    },
    callFunctionsWhenMounted(){
      this.getDeviceLog();
    }
  },
};
</script>
